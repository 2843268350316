import React, { useEffect } from "react";

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top left corner of the page
    // You can also use window.scroll({ top: 0, left: 0, behavior: 'smooth' }); for a smooth scroll
  }, []);

  return null;
}

export default ScrollToTopOnMount;
