import { FaFacebook, FaMapMarker } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTwitter, FaEnvelopeOpen, FaPhone } from "react-icons/fa";

//styling
import "./Contact.scss";
import BottomFooter from "../BottomFooter";
import Footer from "../Footer";
import ScrollToTopOnMount from "../WindowMount";

function ContactNav() {
  return (
    <>
      <ScrollToTopOnMount />
      <div class="contact-container">
        <div class="main-title">
          <h2>
            Contact <span>Us</span>
          </h2>
        </div>
        <div class="contact-content-con">
          <div class="left-contact">
            <div class="groupedContact">
              <h4>Contact Us here</h4>
              <p>Main contact information!</p>
            </div>
            <div class="contact-info">
              <div class="contact-item">
                <div class="icon">
                  <FaMapMarker class="fas fa-map-marker-alt" />
                  Location:
                </div>
                <p>Wigan, UK</p>
              </div>
              <div class="contact-item">
                <div class="icon">
                  <FaEnvelopeOpen class="fas fa-envelope" />
                  <span>Email:</span>
                </div>
                <p>
                  <span>info@zealrealestate.co.uk</span>
                </p>
              </div>
              <div class="contact-item">
                <div class="icon">
                  <FaPhone class="fa-solid fa-phone" />
                  <span>Telephone Number:</span>
                </div>
                <p>
                  <span>01942 411256</span>
                </p>
              </div>
            </div>
          </div>
          <div class="right-contact">
            <form
              action="https://formsubmit.co/info@zealrealestate.co.uk"
              method="POST"
              class="contact-form"
            >
              <div class="input-control i-c-2">
                <input type="hidden" name="_captcha" value="false"></input>
                <input
                  type="text"
                  name="Name"
                  required
                  placeholder="Your Name.."
                />
                <input
                  type="email"
                  name="Email"
                  required
                  placeholder="Your Email.."
                />
              </div>
              <div class="input-control">
                <textarea
                  id=""
                  cols="15"
                  rows="8"
                  placeholder="Message Here..."
                  name="Message"
                ></textarea>
              </div>
              <button className="submitBtn" type="submit" value="Submit">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>

      <>
        <Footer />
      </>
    </>
  );
}

export default ContactNav;
