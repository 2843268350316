import ScrollToTopOnMount from "../WindowMount";
import "./valuation.scss";

function Valuation() {
  return (
    <>
      <ScrollToTopOnMount />
      <div className="backgroundColor">
        <div className="container3">
          <div className="groupedTitleDesc"></div>
          <div className="title">Valuation</div>
          <div className="about">
            Our RICS registered valuers provide RICS compliant valuations for a
            host of clients for both commercial and residential property for
            numerous purposes including: <br></br> <br></br>
            <span>Purchase and Sale </span> <br></br>
            <span>Probate </span> <br></br>
            <span>Taxation </span> <br></br>
            <span>Financial accounting </span> <br></br>
            <span>Market Valuation </span> <br></br>
            <span>Help to Buy / Shared Ownership</span> <br></br>
            <span>Right to Buy </span> <br></br>
            <span>Retrospective Valuation </span> <br></br> <br></br>
            Please get in touch to discuss your valuation requirements.
          </div>
        </div>
      </div>
    </>
  );
}

export default Valuation;
