import React, { useState, useEffect } from "react";

import "./NavBar.scss";

import { Link, NavLink } from "react-router-dom";

function NavBar() {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleLinkClick = () => {
    if (menuOpen) {
      setMenuOpen();
    }
  };

  return (
    <nav className="navBar">
      <Link to="/" className="title">
        <div className="heightWidth">
          <div className="logo"></div>
        </div>
      </Link>
      <div
        className="menu"
        onClick={() => {
          setMenuOpen(!menuOpen);
        }}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
      <ul className={menuOpen ? "open" : ""}>
        <li>
          <NavLink to="/" onClick={handleLinkClick}>
            HOME
          </NavLink>
        </li>
        <li>
          <NavLink to="/about" onClick={handleLinkClick}>
            ABOUT
          </NavLink>
        </li>
        <li>
          <NavLink to="/commercialestateagencies" onClick={handleLinkClick}>
            COMMERCIAL ESTATE AGENCY
          </NavLink>
        </li>
        <li className="white-space">
          <NavLink to="/valuation" onClick={handleLinkClick}>
            VALUATION
          </NavLink>
        </li>
        <li>
          <NavLink to="/leaseadvisory" onClick={handleLinkClick}>
            LEASE ADVISORY
          </NavLink>
        </li>
        <li>
          <NavLink to="/surveys" onClick={handleLinkClick}>
            RESIDENTIAL SURVEYS
          </NavLink>
        </li>
        <li>
          <NavLink to="/contact" onClick={handleLinkClick}>
            CONTACT
          </NavLink>
        </li>
      </ul>
    </nav>
  );
}

export default NavBar;
