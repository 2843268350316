import "./About.scss";
import About from "../About";
import Footer from "../Footer";
import BottomFooter from "../BottomFooter";

function AboutNav() {
  return (
    <>
      <About />
      <Footer />
    </>
  );
}

export default AboutNav;
