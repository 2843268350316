import React, { useState, useEffect } from "react";

const URL = "https://swapi.dev/api/people/1";

export default function ServicesNav() {
  const [results, setResults] = useState([]);

  useEffect(() => {
    async function getData() {
      const response = await fetch(URL);
      const data = await response.json();
      setResults(data);
    }

    getData();

    console.log(results, "results");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <h1>Hello!</h1>
      <div>{results.name}</div>
    </>
  );
}
