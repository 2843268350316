import "./Hero.scss";
import React from "react";
import { FaCheck } from "react-icons/fa";

function HeroBanner() {
  const responsiveSizing = document.querySelector(".heroDirection");
  const responsiveSizing2 = document.querySelector(".innerDirection");

  return (
    <div className="hero-container">
      <div className="img-background">
        <div className="grouped-title">
          <div className="hero-title"> Welcome To Zeal Real Estate</div>
          {/* <div className="hero-subTitle">
            Zeal Real Estate <br></br>
          </div> */}
          <h3 className="hero-sub-heading">
            Commercial and Residential<br></br> property experts based in the
            North West
          </h3>
        </div>
        <div className={responsiveSizing ? "heroDirection" : "heroDirection"}>
          <div className="innerDirection">
            <FaCheck className="pinkTick" />
            <div className="websiteDesign">EXPERT ADVICE</div>
          </div>
          <div className="innerDirection">
            <FaCheck className="pinkTick" />
            <div className="websiteBuild">RESIDENTIAL SURVEYS</div>
          </div>
          <div className="innerDirection">
            <FaCheck className="pinkTick" />
            <div className="websiteMaintenence">VALUATION</div>
          </div>
        </div>
        <div
          className={responsiveSizing2 ? "innerDirection" : "innerDirection"}
        >
          <div className="nextLine">
            <div className="innerDirection">
              <FaCheck className="pinkTick" />
              <div className="SEO">LEASE ADVISORY</div>
            </div>
            <div className="innerDirection">
              <FaCheck className="pinkTick" />
              <div className="branding">COMMERCIAL AGENCY</div>
            </div>
          </div>
        </div>

        {/* <a className="home-btn" href="/about">
          LEARN MORE!
        </a> */}
      </div>
    </div>
  );
}

export default HeroBanner;
