import "./Valuation.scss";

import Footer from "../Footer";
import Valuation from "../Valuation";
import BottomFooter from "../BottomFooter";

function ValuationNav() {
  return (
    <>
      <Valuation />
      <Footer />
    </>
  );
}

export default ValuationNav;
