import "./TopNav.scss";
import { FaEnvelopeOpen } from "react-icons/fa";
import { FaPhone } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import ScrollToTopOnMount from "../WindowMount";

function TopNav() {
  const mobileIcons = document.querySelector(".groupedIcons2");

  return (
    <>
      <ScrollToTopOnMount />
      <div className="topNav-container">
        <div className="flexDirection">
          <div className="groupedIcons">
            <div className="iconSpacing">
              <FaPhone className="phoneNumberImg" />
              <a className="phone-link" href="tel:01942411256">
                <div className="phoneNumber">01942 411256</div>
              </a>
            </div>
            <FaEnvelopeOpen className="emailImg" />
            <div className="email">info@zealrealestate.co.uk</div>
          </div>
          <div className={mobileIcons ? "groupedIcons2" : "nothing"}>
            <div className="fbTwitInsta">
              <a className="fbLink" href="">
                <FaFacebook className="facebookIcon" />
              </a>
              <a href="">
                <FaTwitter className="twitterIcon" />
              </a>
              <a href="">
                <FaInstagram className="instagramIcon" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TopNav;
