import ScrollToTopOnMount from "../WindowMount";
import "./LeaseAdvisory.scss";

function LeaseAdvisory() {
  return (
    <>
      <ScrollToTopOnMount />
      <div className="backgroundColor">
        <div className="container3">
          <div className="groupedTitleDesc"></div>
          <div className="title">Lease Advisory</div>
          <div className="about">
            Are you a property owner or occupier? Do you require comprehensive
            advice and strategy on how to reduce costs or add value? <br></br>
            <br></br>
            Zeal Real Estate provide experienced advice and market knowledge to
            produce the best results for their clients to assist in the
            following landlord and tenant matters:
            <br></br> <br></br>
            <span>Rent Reviews </span> <br></br>
            <span>Lease renewal and lease restructuring. </span> <br></br>
            <span>Prelease building assessments. </span> <br></br>
            <span>Dilapidation consultancy </span> <br></br>
            <span>Schedule of conditions </span> <br></br>
            <span>General lease matters</span> <br></br>
          </div>
        </div>
      </div>
    </>
  );
}

export default LeaseAdvisory;
