import "./GetToKnow.scss";

import Image from "../../src/ServicesGetToKnow.jpeg";
import Image2 from "../../src/ZealRealHouse.png";
import Image3 from "../../src/ZealRealCommercial.png";

function GetToKnow() {
  return (
    <>
      <h1 className="getToKnowUs"></h1>

      <div className="groupedHeadings">
        <h1 className="topH1Line">
          If you are looking for a modern, commercial property agent
        </h1>
        <h1 className="middleH1Line">
          with in-depth local knowledge, then look no further!{" "}
        </h1>
        <h2 className="bottomH2Line">
          Zeal Real Estate are proud of our clear, and down to earth, personable
          team.
        </h2>
      </div>

      <div className="imageRow">
        <div className="splitImgLink">
          <div className="widthHeight">
            <div className="logoBackground"></div>
          </div>

          <a href="/surveys">
            <div className="wrapper">
              <div className="img-box">
                <div className="info">
                  {/* <div className="imgBackground" /> */}
                  <img className="imgBackground" src={Image2} alt="cam" />
                  <h1>Residential Properties</h1>
                </div>
                <div className="desc">
                  {/* <h1>Details 1</h1> */}
                  <p>Click here to explore more!</p>
                </div>
              </div>
            </div>
          </a>

          <a href="/commercialestateagencies">
            <div className="wrapper">
              <div className="img-box">
                <div className="info">
                  {/* <div className="imgBackground" /> */}
                  <img className="imgBackground" src={Image3} alt="cam" />
                  <h1>Commercial Properties</h1>
                </div>
                <div className="desc">
                  {/* <h1>Details 1</h1> */}
                  <p>Click here to learn more!</p>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </>
  );
}

export default GetToKnow;
