import React from "react";
import Footer from "../Footer";
import HeroBanner from "../Hero";
import GetToKnow from "../GetToKnow";
import Help from "../Help";
import HomeContact from "../HomeContact/HomeNav";
import BottomFooter from "../BottomFooter";
import ScrollToTopOnMount from "../WindowMount";

function HomeNav() {
  return (
    <>
      <ScrollToTopOnMount />
      <HeroBanner />
      <Help />
      <GetToKnow />
      <HomeContact />
      <Footer />
    </>
  );
}

export default HomeNav;
