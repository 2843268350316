import "./Footer.scss";

import { FaPhone } from "react-icons/fa";
import { FaEnvelopeOpen } from "react-icons/fa";

//IMG
import RICSimg from "../../src/RICS.jpg";

function Footer() {
  return (
    <>
      <div className="lineBreak"></div>
      <div className="footer-container">
        <div className="boxDropdowns">
          <div className="columnDropdown">
            <div className="contactSection">
              <div className="contactSectionTitle">CONTACT US</div>
              <div className="footerOurOffice">
                1st Floor , Wigan Westward House, King Street West, Wigan WN1
                1LP Zeal Real Estate Limited Registered <br></br> as a company
                in England and Wales Co Number:15231872.
              </div>

              <br></br>
              <p>Email: info@zealrealestate.co.uk</p>
              <p>Phone Number: 01942 411256</p>
              <p></p>

              <div className="fbTwitInsta2">
                <a className="fbLink" href="">
                  <FaEnvelopeOpen className="facebookIcon2" />
                </a>
                <a href="tel:01942411256">
                  <FaPhone className="twitterIcon2" />
                </a>
              </div>
              <img className="RICSimg" src={RICSimg} alt="RICS" />
              <div className="ZRE">Zeal Real Estate</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
