import "./Help.scss";

function Help() {
  return (
    <div className="backgroundColor">
      <div className="container3">
        <div className="aboutDirection">
          <div className="groupedTitleDesc">
            {/* <div className="title">How We Can Help</div> */}
            <div className="about3">
              We are a professional and reliable property consultancy who
              achieve the best results for our clients. We provide property
              services across the sectors. <br></br> <br></br>Please use our
              contact form below to make an enquiry and get in touch today and
              see how Zeal Real Estate can assist you!
            </div>
            <div className="groupedBtn">
              <a className="about-btn3" href="/contact">
                Enquire Now
              </a>{" "}
              {/* <a className="about-btn2" href="www.footasylun.com">
                SEND A MESSAGE
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Help;
