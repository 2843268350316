import "./About.scss";
import ScrollToTopOnMount from "../WindowMount";

function About() {
  return (
    <>
      <ScrollToTopOnMount />
      <div className="backgroundColor">
        <div className="container3">
          <div className="aboutDirection">
            <div className="groupedTitleDesc">
              <div className="title">About Us</div>
              <div className="about">
                Zeal Real Estate is a relatively new business who is looking to
                take the commercial property and residential surveying markets
                by storm. We offer comprehensive, jargon free advice to
                commercial and residential property owners and occupiers. Our
                staff are highly trained individuals who endeavour to offer the
                highest standard of advice and consultancy service to both
                commercial landlords and tenants and residential property
                purchasers. <br></br> <br></br>We offer a broad range of
                commercial and residential property services including:<br></br>
                <br></br>{" "}
                <span>
                  RICS Compliant Valuations Property/ Asset Management{" "}
                </span>
                <br></br>
                <span>Lease Renewals </span>
                <br></br>
                <span>Rent Reviews </span>
                <br></br>
                <span>Commercial Estate Agency</span>
                <br></br>{" "}
                <span>Pre purchase residential building surveying.</span>
                <br></br> <br></br> This list is not exhaustive, so please get
                in touch with your queries and see if we can help! We are RICS
                regulated which means we follow a strict rule of conduct and
                endeavour to provide the best standard of service to all clients
                in the most professional manner.
              </div>
              <div className="groupedBtn">
                <a className="about-btn1" href="/contact">
                  Contact Us
                </a>{" "}
              </div>
            </div>
            {/*
    <div className="secondAboutCont">
      <div className="manageContent">
        <div className="boldTitle">Manage Your Content</div>
        <p className="SecondAbouteDesc">
          You will be able to give us the feedback you require to get your
          website looking how you expect!
        </p>
      </div>
      <div className="bespoke">
        <div className="boldTitle">Bespoke</div>
        <p className="SecondAbouteDesc">
          No themes, no code, just bespoke and easy. Every website I build
          is customly built to your requirements.
        </p>
      </div>
      <div className="Andy">
        <div className="boldTitle">AI Text Integration</div>
        <p className="SecondAbouteDesc">
          All websites can be built with the new AI Text functionality.
          This allows you to create articles and content within minutes!
        </p>
      </div>
    </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
