import "./CEA.scss";

import Footer from "../Footer";
import CEA from "../CEA";
import BottomFooter from "../BottomFooter";

function CEANav() {
  return (
    <>
      <CEA />
      <Footer />
    </>
  );
}

export default CEANav;
