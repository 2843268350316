import "./Surveys.scss";
import Surveys from "../Surveys";
import Footer from "../Footer";
import BottomFooter from "../BottomFooter";

function SurveysNav() {
  return (
    <>
      <Surveys />
      <Footer />
    </>
  );
}

export default SurveysNav;
