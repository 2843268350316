import "./CEA.scss";
import ScrollToTopOnMount from "../WindowMount";

function CEA() {
  return (
    <>
      <ScrollToTopOnMount />
      <div className="backgroundColor">
        <div className="container3">
          <div className="groupedTitleDesc"></div>
          <div className="title">Commercial Estate Agency</div>
          <div className="about">
            We offer a comprehensive tailored service for sales and lettings of
            commercial property. We cover the whole of the North West on all
            types of commercial property, including: Industrial, Offices,
            Retail, Leisure and Land. Our well thought out approach helps
            achieve the best outcome when selling or leasing a property. We
            tailor our marketing approach when placing properties on the market
            to ensure the best results are achieved for our clients.{" "}
          </div>
        </div>
      </div>
    </>
  );
}

export default CEA;
