import "./LeaseAdvisory.scss";

import Footer from "../Footer";
import LeaseAdvisory from "../LeaseAdvisory";
import BottomFooter from "../BottomFooter";

function LeaseAdvisoryNav() {
  return (
    <>
      <LeaseAdvisory />
      <Footer />
    </>
  );
}

export default LeaseAdvisoryNav;
