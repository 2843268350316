import { Route, Routes } from "react-router-dom";
import React, { useEffect } from "react";
// import About from "./About";
// import Footer from "./Footer";
// import HeroBanner from "./Hero";
import NavBar from "./NavBar";
import TopNav from "./TopNav";

//Pages
import AboutNav from "./pages/About";
import ContactNav from "./pages/Contact";
import ServicesNav from "./pages/Services";
import HomeNav from "./pages/Home";
import CEANav from "./pages/CEA";
import ValuationNav from "./pages/Valuation";
import LeaseAdvisoryNav from "./pages/LeaseAdvisory";
import SurveysNav from "./pages/Surveys";

function App() {
  return (
    <>
      <TopNav />
      <NavBar />
      <Routes>
        <Route path="/" element={<HomeNav />} />
        <Route path="/about" element={<AboutNav />} />
        <Route path="/contact" element={<ContactNav />} />
        <Route path="/properties" element={<ServicesNav />} />
        <Route path="/commercialestateagencies" element={<CEANav />} />
        <Route path="/valuation" element={<ValuationNav />} />
        <Route path="/leaseadvisory" element={<LeaseAdvisoryNav />} />
        <Route path="/surveys" element={<SurveysNav />} />
      </Routes>
    </>
  );
}

export default App;
