import ScrollToTopOnMount from "../WindowMount";
import "./Surveys.scss";

function Surveys() {
  return (
    <>
      <ScrollToTopOnMount />
      <div className="backgroundColor">
        <div className="container3">
          <div className="groupedTitleDesc"></div>
          <div className="title">Residential Surveys</div>
          <div className="about">
            We understand a property purchase is a big commitment, and more so
            it is imperative that you understand the condition of the investment
            prior to purchasing it. <br></br>
            <br></br>
            We offer a range of RICS surveys which are undertaken by RICS
            professionals to give comfort and understanding to your property
            purchase.
            <br></br>
            We provide the following surveys:
            <br></br> <br></br>
            <span>Home Condition Report Level 1 </span> <br></br>
            <span>Homebuyer Report Level 2 without Market Valuation </span>{" "}
            <br></br>
            <span>Homebuyer Report Level 2 without Market Valuation </span>{" "}
            <br></br>
            <span>Building Survey Report Level 3 </span> <br></br>
          </div>
        </div>
      </div>
    </>
  );
}

export default Surveys;
