import "./HomeNav.scss";

function HomeContact() {
  return (
    <>
      <h1 className="getToKnowUs"></h1>
      <div class="contact-container">
        <div class="main-title2">
          <h2>Get In Touch Today!</h2>
        </div>
        <div class="contact-content-con">
          <div class="right-contact">
            <form
              action="https://formsubmit.co/info@zealrealestate.co.uk"
              method="POST"
              class="contact-form"
            >
              <div class="input-control i-c-2">
                <input
                  type="text"
                  name="Name"
                  required
                  placeholder="Your Name"
                />
                <input
                  type="email"
                  name="Email"
                  required
                  placeholder="Your Email"
                />
              </div>
              <div class="input-control">
                <textarea
                  name="Message Here"
                  id=""
                  cols="15"
                  rows="8"
                  placeholder="Message Here..."
                ></textarea>
              </div>
              <input className="submitBtn" type="submit" value="Submit"></input>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomeContact;
